/* eslint-disable import/prefer-default-export */
const axios = require('axios');

/**
 * Log a page load
 * @param env
 * @param id
 * @returns {Promise<any>}
 */
const logPageLoad = async (uid, url) => {
  const data = {
    url,
    uid,
  };
  const urls = {
    dev: 'https://api.opzoom.com/signups/dev',
    default: 'https://api.opzoom.com/signups/test',
  };

  const { BUILD_ENV } = process.env;
  // eslint-disable-next-line operator-linebreak
  const ROOT_URL =
    BUILD_ENV && urls[BUILD_ENV] ? urls[BUILD_ENV] : urls.default;
  const response = await axios({
    method: 'post',
    url: `${new URL(ROOT_URL)}/price/logPageLoad`,
    data,
  });

  return response.data;
};

/**
 * Steps in sequence.
 * @param stage
 * @returns {Promise<void>}
 */
export const test = async ({ uid, url }) => {
  try {
    // const out = await logPageLoad(settings[stage], uid, url)
    const out = await logPageLoad(uid, url);
    if (out && out.user) {
      document.cookie = `uid=${out.user.id};path="/"`;
    }
    // console.dir(out, { depth: null });
  } catch (error) {
    console.error(error);
  }
};

/**
 * Format :
 * node test/sim/pageLoad [test|dev|sim]
 */
// test({ stage: process.argv[2] })
